<template>
  <div class="relative">
    <i :class="[icon, $attrs.class]"></i>
    <i
      :class="[{ 'opacity-100': blink }, $attrs.class, blinkingIcon]"
      class="absolute left-0 top-0 opacity-0 transition-opacity duration-500"
    ></i>
  </div>
</template>
<script lang="ts" setup>
defineOptions({
  inheritAttrs: false,
});
const props = defineProps<{
  blinkingIcon: string;
  icon: string;
  timeout?: number;
}>();

const blink = ref(false);
let interval: NodeJS.Timeout | undefined;

onMounted(() => {
  interval = setInterval(() => {
    blink.value = !blink.value;
  }, props.timeout || 1000);
});
onUnmounted(() => {
  clearInterval(interval);
});
</script>
